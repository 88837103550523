import React, { useState } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Form,
    FormGroup,
    Label,
    Input
} from 'reactstrap';
import { SC } from "../../services/Api/serverCall";
import toast from 'react-hot-toast';

const ChangePasswordModal = ({ isOpen, toggle }) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const handleSubmit = () => {
        let payload = {
            "oldPassword": oldPassword,
            "newPassword": newPassword
        }

        SC.postCall({
            url: "portal/C01PortalChangePassword",
            data: payload,
        })
            .then((res) => {
                if (res?.data?.CODE === 1) {
                    toast?.success("Paswword Updated Successfully!")
                }
                setOldPassword('')
                setNewPassword('')
            })
            .catch((error) => {
                console.log("ERROR", error);
            });
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Change Password</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="oldPassword">Old Password</Label>
                        <Input
                            type="password"
                            name="oldPassword"
                            id="oldPassword"
                            placeholder="Enter your old password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                        />
                        <Label style={{ marginTop: 20 }} for="newPassword">New Password</Label>
                        <Input
                            type="password"
                            name="newPassword"
                            id="newPassword"
                            placeholder="Enter your new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSubmit}>
                    Change Password
                </Button>{' '}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ChangePasswordModal;
