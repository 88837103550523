import { Fragment } from "react";
import NavbarUser from "./NavbarUser";
import { handleMenuCollapsed } from "../../../../redux/layout";
import { useDispatch } from "react-redux";
import { menuCollapsor } from "../../../../assets/wasfaty/SVG";
import { createBrowserHistory } from "history";
import { useNavigate } from "react-router-dom";

const ThemeNavbar = (props) => {
  const { skin, setSkin, setMenuVisibility, menuCollapsed, windowWidth } = props;
  const dispatch = useDispatch();
  const history = createBrowserHistory();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem('userData'))
  const CollapsToggler = () => {
    return (
      <div
        className="collapsToggler"
        style={{ display: windowWidth <= 1200 ? "block" : "none" }}
      >
        <figure
          className={menuCollapsed ? "rotate" : ""}
          onClick={() =>
            windowWidth <= 1200
              ? setMenuVisibility(true)
              : dispatch(handleMenuCollapsed(!menuCollapsed))
          }
          style={{ cursor: "pointer" }}
        >
          {menuCollapsor}
        </figure>
      </div>
    );
  };

  const logoStyles = {
    height: windowWidth <= 576 ? 30 : windowWidth <= 768 ? 40 : 50,
    width: windowWidth <= 576 ? 100 : windowWidth <= 768 ? 120 : 150,
    marginBottom: '5%'
  };

  return (
    <Fragment>
      {userData?.id !== 2381 &&
        <div className="bookmark-wrapper d-flex align-items-center">
          <CollapsToggler />
          <ul className="mb-0" style={{ paddingLeft: "10px" }}>
            <p className="mb-0 Nav-title d-flex align-items-center">
              <img
                src={require("../../../../assets/images/sbc_logo.png").default}
                style={logoStyles}
                alt="Logo"
              />
            </p>
          </ul>
        </div>
      }

      <NavbarUser skin={skin} setSkin={setSkin} windowWidth={windowWidth} />
    </Fragment>
  );
};

export default ThemeNavbar;
