import { createSlice } from "@reduxjs/toolkit";

const langSlice = createSlice({
  name: "LangSlice",
  initialState: {
    languages: null,
    PreferredLanguage: null,
    selectedLang: null,
  },
  reducers: {
    setLang: (state, action) => {
      state.languages = action?.payload;
    },
    setPreferredLanguage: (state, action) => {
      state.PreferredLanguage = action?.payload;
    },
    setSelectedLang: (state, action) => {
      state.selectedLang = action?.payload;
    },
  },
});

export const { setLang, setPreferredLanguage, setSelectedLang } = langSlice.actions;
export default langSlice.reducer;

// Selectors
export const selectLang = (state) => state.language.languages;
export const selectPreferredLanguage = (state) =>
  state.language.PreferredLanguage;
export const selectSelectedLang = (state) =>
  state.language.selectedLang;
