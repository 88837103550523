// ** Reducers Imports
import navbar from "./navbar";
import language from "./language";
import layout from "./layout";
import charts from "./charts";
import col from "./colSize";
import allRoutesStore from "./allroutesSlice";
import userSlice from "./userSlice";

const rootReducer = {
  navbar,
  layout,
  language,
  charts,
  col,
  allRoutesStore,
  userSlice
};

export default rootReducer;
