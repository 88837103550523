import { useNavigate } from "react-router-dom";
import { Badge } from "reactstrap";
import React from "react";

const WorkFlowDropdown = () => {
    const navigate = useNavigate();
    const dashboardStorage = JSON.parse(localStorage.getItem("dashboardStorage"));
    return (
        <div
            style={{ cursor: 'pointer', position: 'relative', display: 'inline-block' }}
            onClick={() => navigate("/WorkFlow")}
        >
            <img
                src={require("../../../../assets/images/requests.png").default}
                style={{ width: 40, height: 40 }}
                alt="Logo"
            />
            <Badge
                pill
                color="primary"
                className="badge-up"
                style={{
                    position: 'absolute',
                    top: -6,
                    right: -10,
                    fontSize: '0.8rem',
                }}
            >
                {dashboardStorage?.actionAwaited}
            </Badge>
        </div>
    );
};

export default WorkFlowDropdown;
