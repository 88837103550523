import { createSlice } from "@reduxjs/toolkit";

const chartSlice = createSlice({
  name: "ChartSlice",
  initialState: {
    chartList: [],
  },
  reducers: {
    setChartSlice: (state, action) => {
      state.chartList = action?.payload;
    },
  },
});

export const { setChartSlice } = chartSlice.actions;
export default chartSlice.reducer;

// Selectors
export const selectChartSlice = (state) => state.charts.chartList;
