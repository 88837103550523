import { Fragment, useState, useEffect } from 'react';
import {
    Row, Col, CardHeader, Card, CardBody,
    Spinner, Button, Modal, ModalBody, ModalFooter, ModalHeader, Input
} from 'reactstrap';
import BreadCrumbs from '@components/breadcrumbs';
import Timeline from '../../components/Timeline';
import { useNavigate, useLocation } from 'react-router-dom';
import '@styles/react/apps/app-users.scss';
import { SC } from '../../../services/Api/serverCall';
import MapView from "../../components/MapView";
import DaysSelector from '../../components/DaysSelector'
import toast from 'react-hot-toast';

const daysOfWeek = [
    { label: "Sun", id: 1 },
    { label: "Mon", id: 2 },
    { label: "Tue", id: 3 },
    { label: "Wed", id: 4 },
    { label: "Thr", id: 5 },
    { label: "Fri", id: 6 },
    { label: "Sat", id: 7 }
];

const WorkFlowDetail = ({ }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { myRequestsData, activeTab } = location.state || {};
    const [loader, setLoader] = useState(false)
    const [coordinatesLoader, setCoordinatesLoader] = useState(false)
    const [loaderApprove, setLoaderApprove] = useState(false)
    const [loaderDecline, setLoaderDecline] = useState(false)
    const [remarksModalOpen, setRemarksModalOpen] = useState(false)
    const [coordinates, setCoordinates] = useState([])
    const [rquestResponse, setRquestResponse] = useState([])
    const [actionIdState, setActionIdState] = useState(null)
    const [userRemark, setUserRemark] = useState('')
    const [slectedDays, setSlectedDays] = useState([])
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];

    const isImage = (value) => {
        return typeof value === 'string' && imageExtensions.some(ext => value.toLowerCase().endsWith(ext));
    };

    useEffect(() => {
        getRequestDetail()
    }, [])

    const getRequestDetail = () => {
        setLoader(true)
        let payload = {
            "processId": myRequestsData?.processId,
            "requestId": myRequestsData?.requestId
        }

        SC.postCall({
            url: 'portal/C01PortalGetWorkflowRequestDetails',
            data: payload
        })
            .then((response) => {
                console.log("C01PortalGetWorkflowRequestDetails", response)
                setRquestResponse(response?.data?.DATA)
                onSetCoordinates(response?.data?.DATA?.requestDetailsData)

                setLoader(false);
            })
            .catch((error) => {
                console.log('ERROR', error);
                setLoader(false);
            });
    }

    const onSetCoordinates = (item) => {
        setCoordinatesLoader(true);
        let array = []
        const outletLat = item?.Lat ?? null;
        const outletLng = item?.Lng ?? null;

        if (outletLat !== null && outletLng !== null) {
            const updatedCoordinates = [
                ...array,
                {
                    lat: outletLat,
                    lng: outletLng,
                    icon: require("../../../assets/images/outletMapMarker.png"),
                    type: "outlet",
                },
            ];
            setCoordinates(updatedCoordinates);
        }

        setCoordinatesLoader(false);
    };

    const selectedDaysCall = (days) => {
        setSlectedDays(days)
    };

    const onUpdateRquestAPI = () => {
        if (actionIdState === 1) {
            setLoaderApprove(true)
        }
        if (actionIdState === 2) {
            setLoaderDecline(true)
        }

        const userData = JSON.parse(localStorage.getItem('userData'))
        let payload = {
            "requestId": myRequestsData?.requestId,
            "actionId": actionIdState,
            "userId": userData?.id,
            "remarks": userRemark,
            "days": slectedDays
        }

        SC.postCall({
            url: 'portal/C01PortalWorkflowRequestPerformAction',
            data: payload
        })
            .then((response) => {
                if (response?.data?.CODE === 1) {
                    toast?.success(response?.data?.USER_MESSAGE)
                    setLoaderApprove(false)
                    setLoaderDecline(false)
                    getRequestDetail()
                }
                setLoader(false);
            })
            .catch((error) => {
                console.log('ERROR', error);
                setLoader(false);
                setLoaderApprove(false)
                setLoaderDecline(false)
            });
    }

    return (
        <>
            <div className='app-user-view'>
                <BreadCrumbs
                    title="Theia"
                    data={[{ title: "Request List" }, { title: "Summary" }]}
                    onPress={() => navigate(-1)}
                    onPressReport={() => { }}
                />
                <Row>
                    <Col md='3'>
                        <Timeline
                            title={"Approval Chain"}
                            processSteps={rquestResponse?.requestChain}
                            activeTab={activeTab}
                        />
                        {activeTab === "1" &&
                            <>
                                <Row style={{ marginLeft: '5%', marginRight: '5%' }}>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        style={{ marginBottom: '5%' }}
                                        onClick={(e) => {
                                            setActionIdState(1)
                                            setRemarksModalOpen(true)
                                        }}
                                    >
                                        {loaderApprove ?
                                            <Spinner />
                                            :
                                            rquestResponse?.requestChain?.find((step) => step.isCurrentStep)?.stepActionButtonLabel || 'Default Label'
                                        }
                                    </Button>
                                    <Button
                                        color="danger"
                                        type="submit"
                                        style={{ marginBottom: '5%' }}
                                        onClick={(e) => {
                                            setRemarksModalOpen(true)
                                            setActionIdState(2)
                                        }}
                                    >
                                        {loaderDecline ? <Spinner /> : "Decline"}
                                    </Button>
                                </Row>
                            </>
                        }
                    </Col>
                    <Col md='9'>
                        <Card>
                            <CardHeader>
                                <h4>{myRequestsData?.processName}</h4>
                            </CardHeader>
                            <CardBody>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-between'
                                }}>
                                    {loader ? (
                                        <Spinner />
                                    ) : (
                                        !!rquestResponse?.requestDetailsData && (() => {
                                            const data = rquestResponse.requestDetailsData;
                                            const textData = [];
                                            const imageData = [];
                                            let daysData = [];
                                            Object.keys(data).forEach((key) => {
                                                const value = data[key];
                                                if (Array.isArray(value)) {
                                                    daysData = data?.Outlet_Request_Routes;
                                                } else if (isImage(value)) {
                                                    imageData.push({ key, value });
                                                } else {
                                                    textData.push({ key, value });
                                                }
                                            });

                                            const isDaySelected = (dayId) => daysData.includes(dayId);
                                            return (
                                                <>
                                                    <div>
                                                        {myRequestsData?.processName === "Outlet Registration Request" &&
                                                            <DaysSelector
                                                                daysOfWeek={daysOfWeek}
                                                                initialSelectedDays={daysData}
                                                                selectedDaysCall={selectedDaysCall}
                                                                size={"sm"}
                                                            />
                                                        }
                                                    </div>
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                                        {textData.map(({ key, value }, index) => (
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    backgroundColor: '#F8F8F8',
                                                                    padding: 8,
                                                                    borderRadius: 5,
                                                                    width: 'calc(33% - 10px)',
                                                                    marginBottom: 10
                                                                }}
                                                            >
                                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>{key}</span>
                                                                <div
                                                                    style={{
                                                                        fontSize: 10,
                                                                        marginTop: 5,
                                                                        color: '#0E72AD',
                                                                        fontWeight: 'bold'
                                                                    }}
                                                                >
                                                                    {value || 'N/A'}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div>
                                                        {data?.Outlet_Request_Contacts?.length > 0 &&
                                                            <>
                                                                <h3 style={{ marginTop: 20 }}>Contacts Detail</h3>
                                                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', }}>
                                                                    {data.Outlet_Request_Contacts.map((contact, index) => (
                                                                        <div
                                                                            key={index}
                                                                            style={{
                                                                                backgroundColor: '#F0F0F0',
                                                                                padding: 10,
                                                                                borderRadius: 5,
                                                                                width: '300px',
                                                                                marginBottom: 10
                                                                            }}
                                                                        >
                                                                            {contact.Owner_Name && (
                                                                                <div>
                                                                                    <span style={{ fontSize: 12, fontWeight: 'bold' }}>Owner Name:</span>
                                                                                    <div style={{ fontSize: 10, marginTop: 5 }}>{contact.Owner_Name}</div>
                                                                                </div>
                                                                            )}
                                                                            {contact.Owner_Contact && (
                                                                                <div>
                                                                                    <span style={{ fontSize: 12, fontWeight: 'bold' }}>Owner Contact:</span>
                                                                                    <div style={{ fontSize: 10, marginTop: 5 }}>{contact.Owner_Contact}</div>
                                                                                </div>
                                                                            )}
                                                                            {contact.Assistant_Name && (
                                                                                <div>
                                                                                    <span style={{ fontSize: 12, fontWeight: 'bold' }}>Assistant Name:</span>
                                                                                    <div style={{ fontSize: 10, marginTop: 5 }}>{contact.Assistant_Name}</div>
                                                                                </div>
                                                                            )}
                                                                            {contact.Assistant_Contact && (
                                                                                <div>
                                                                                    <span style={{ fontSize: 12, fontWeight: 'bold' }}>Assistant Contact:</span>
                                                                                    <div style={{ fontSize: 10, marginTop: 5 }}>{contact.Assistant_Contact}</div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    <div style={{ width: '100%', marginTop: 10 }}>
                                                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                                            {imageData.length > 0 ? (
                                                                imageData.map(({ key, value }, imgIndex) => (
                                                                    <img
                                                                        key={imgIndex}
                                                                        src={value} // Directly use the value as URL
                                                                        alt={key}
                                                                        onError={(e) => e.target.src = 'https://via.placeholder.com/150'} // Fallback image
                                                                        style={{
                                                                            width: '250px', // Adjust size as needed
                                                                            height: 'auto',
                                                                            borderRadius: 5
                                                                        }}
                                                                    />
                                                                ))
                                                            ) : (
                                                                null
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })()
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <div>
                                <MapView
                                    coordinates={coordinates}
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
            <div className="disabled-backdrop-modal">
                <Fragment>
                    <Modal
                        isOpen={remarksModalOpen}
                        toggle={() => setModalOpen(!remarksModalOpen)}
                        className="modal-dialog-centered"
                        backdrop={false}
                    >
                        <ModalHeader
                            toggle={() => {
                                setRemarksModalOpen(!remarksModalOpen);
                            }}
                        >
                            Give Remarks
                        </ModalHeader>
                        <ModalBody>
                            <Input
                                placeholder="write here..."
                                onChange={(e) => {
                                    setUserRemark(e?.target?.value);
                                }}
                                style={{ marginTop: 20 }}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => {
                                    onUpdateRquestAPI()
                                }}
                            >
                                Proceed
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Fragment>
            </div>
        </>
    );
};

export default WorkFlowDetail;
