// ** Icons Import
import { Heart } from "react-feather";
import { useIntl } from "react-intl";

const Footer = () => {
  const translation = useIntl();
  return (
    <p className="clearfix mb-0">
      <span className="float-md-start d-block d-md-inline-block mt-25">
        {translation.formatMessage({
          id: "COPYRIGHT",
          defaultMessage: "COPYRIGHT",
        })}
        © {new Date().getFullYear()}{" "}
        <a href="http://kale-labs.com/" target="_blank" rel="noopener noreferrer">
          {translation.formatMessage({
            id: "Kale Labs",
            defaultMessage: "Kale Labs",
          })}
        </a>
        <span className="d-none d-sm-inline-block">
          {", "}
          {translation.formatMessage({
            id: "All rights Reserved",
            defaultMessage: "All rights Reserved",
          })}
        </span>
      </span>
    </p>
  );
};

export default Footer;
