// ** Third Party Components
import { Suspense, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { IntlContext } from "../../../../utility/context/IntelContext";
import { useDispatch, useSelector } from "react-redux";
import { SC } from "../../../../services/Api/serverCall";
import { setSelectedLang } from "../../../../redux/language";
import { useIntl } from "react-intl";

const IntlDropdown = () => {
  // ** Hooks
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const intlContext = useContext(IntlContext);
  const translation = useIntl();
  // ** Vars

  useEffect(() => {
    // getLanguages();
  }, []);

  // ** Function to switch Language
  const handleLangUpdate = (e, lang) => {
    e.preventDefault();

    intlContext.switchLanguage(lang);
    // i18n.changeLanguage(lang)
  };

  // const getLanguages = () => {
  //   SC.getCall({ url: "getLanguages" })
  //     .then((response) => {
  //       console.log("=-=-RESPOSNE GET LANG", response?.data?.DATA);
  //       let myArray = response?.data?.DATA;
  //       let result = myArray.map((i) => {
  //         return i?.short_code;
  //       });
  //       dispatch(setSelectedLang(result));
  //     })
  //     .catch((error) => {
  //       console.log("ERROR", error);
  //     });
  // };

  return (
    <UncontrolledDropdown
      href="/"
      tag="li"
      className="dropdown-language nav-item"
    >
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link"
        onClick={(e) => e.preventDefault()}
      >
        <ReactCountryFlag
          svg
          className="country-flag flag-icon"
          countryCode={
            intlContext?.locale === "ar"
              ? "sa"
              : intlContext?.locale === "en"
              ? "us"
              : "ph"
          }
        />
        <span className="selected-language">
          {intlContext?.locale === "ar"
            ? translation.formatMessage({
                id: "Arabic",
                defaultMessage: "Arabic",
              })
            : intlContext?.locale === "en"
            ? translation.formatMessage({
                id: "English",
                defaultMessage: "English",
              })
            : translation.formatMessage({
                id: "Filipino",
                defaultMessage: "Filipino",
              })}
        </span>
      </DropdownToggle>
      <DropdownMenu className="mt-0" end>
        <DropdownItem
          href="/"
          tag="a"
          onClick={(e) => handleLangUpdate(e, "en")}
        >
          <ReactCountryFlag className="country-flag" countryCode="us" svg />
          <span className="ms-1">
            {translation.formatMessage({
              id: "English",
              defaultMessage: "English",
            })}
          </span>
        </DropdownItem>
        <DropdownItem
          href="/"
          tag="a"
          onClick={(e) => handleLangUpdate(e, "ar")}
        >
          <ReactCountryFlag className="country-flag" countryCode="sa" svg />
          <span className="ms-1">
            {translation.formatMessage({
              id: "Arabic",
              defaultMessage: "Arabic",
            })}
          </span>
        </DropdownItem>
        <DropdownItem
          href="/"
          tag="a"
          onClick={(e) => handleLangUpdate(e, "ph")}
        >
          <ReactCountryFlag className="country-flag" countryCode="ph" svg />
          <span className="ms-1">
            {translation.formatMessage({
              id: "Filipino",
              defaultMessage: "Filipino",
            })}
          </span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default IntlDropdown;
