import { Fragment, useState, useContext, useEffect } from "react";
import Select from "react-select";
import Cleave from "cleave.js/react";
import { useForm, Controller } from "react-hook-form";
import "cleave.js/dist/addons/cleave-phone.us";
import Avatar from "./avatar";
import avatarImg from "./Avatar.webp";
import { SC } from "../../../services/Api/serverCall";
import toast from "react-hot-toast";
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Label,
  Button,
  CardBody,
  CardTitle,
  CardHeader,
  FormFeedback,
} from "reactstrap";
import { selectThemeColors } from "@utils";
import { useDispatch } from "react-redux";
import { handleRTL } from "../../../redux/layout";
import { IntlContext } from "../../../utility/context/IntelContext";
import { useIntl } from "react-intl";
const baseUrlImages = process.env.REACT_APP_IMAGES_BASEURL;

const AccountSetting = ({ data }) => {
  const dispatch = useDispatch();
  const intlContext = useContext(IntlContext);
  const [userData, setUserData] = useState(null);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [address, setAddress] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(avatarImg);
  const translation = useIntl();
  const loginUser = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    if (userData) {
      setProfileImage(userData.img_label);
      setProfileImageUrl(
        `${baseUrlImages}images/userProfileImages/${userData.img_label}`
      );
      setFirstName(userData.firstName);
      setLastName(userData.lastName);
      setAddress(userData.address);
      setDisplayName(userData.displayName);
      setEmail(userData.email);
      setPhone(userData.mobileNumber);
    }
  }, [userData]);

  const getUserProfile = () => {
    let payload = {
      user_id: loginUser?.id,
    };
    SC.postCall({ url: "portal/C01PortalGetUserDetails", data: payload })
      .then((response) => {
        console.log("=-=-response getUserProfile", response?.data?.DATA);
        setUserData(response?.data?.DATA);
      })
      .catch((error) => {
        console.log("ERRORs", error?.response);
      });
  };

  const handleProfileInfo = async (e) => {
    e.preventDefault();
    if(displayName === "") {
      toast.error("Display Name is required!");
      return
    }
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("displayName", displayName);
    formData.append("address", address);
    formData.append("email", email);
    formData.append("mobileNumber", phone);
    if (profileImage) {
      formData.append("profileImage", profileImage);
    }

    SC.postCall({ url: "portal/C01PortalUserProfileUpdate", data: formData })
      .then((response) => {
        console.log("=-=-Profile Update", response);
        toast.success(response?.data?.USER_MESSAGE);
        getUserProfile();
      })
      .catch((error) => {
        console.log("ERRORs", error?.response);
      });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
    setProfileImageUrl(URL.createObjectURL(file));
  };

  return (
    <Fragment>
      <Card>
        <CardHeader className="border-bottom">
          <CardTitle tag="h4">
            {translation.formatMessage({
              id: "Profile Details",
              defaultMessage: "Profile Details",
            })}
          </CardTitle>
        </CardHeader>
        <CardBody className="py-2 my-25">
          <div className="d-flex">
            <div className="me-25">
              {console.log("=-=-profileImage", profileImage)}
              <img
                className="rounded me-50"
                src={profileImageUrl}
                alt="Generic placeholder image"
                height="100"
                width="100"
              />
            </div>
            <div className="d-flex align-items-end mt-75 ms-1">
              <div>
                <Button
                  tag={Label}
                  className="mb-75 me-75"
                  size="sm"
                  color="primary"
                >
                  {translation.formatMessage({
                    id: "Upload",
                    defaultMessage: "Upload",
                  })}
                  <Input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </Button>
                <Button className="mb-75" color="secondary" size="sm" outline>
                  {translation.formatMessage({
                    id: "Reset",
                    defaultMessage: "Reset",
                  })}
                </Button>
                <p className="mb-0">
                  Allowed JPG, GIF or PNG. Max size of 800kB
                </p>
              </div>
            </div>
          </div>

          <Form className="mt-2 pt-50">
            <Row>
              <Col sm="6" className="mb-1">
                <Label className="form-label" for="firstName">
                  {translation.formatMessage({
                    id: "First Name",
                    defaultMessage: "First Name",
                  })}
                </Label>
                <Input
                  id="firstName"
                  placeholder="John"
                  value={firstName}
                  onChange={(e) => setFirstName(e?.target?.value)}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <Label className="form-label" for="lastName">
                  {translation.formatMessage({
                    id: "Last Name",
                    defaultMessage: "Last Name",
                  })}
                </Label>
                <Input
                  id="lastName"
                  placeholder="Doe"
                  value={lastName}
                  onChange={(e) => setLastName(e?.target?.value)}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <Label className="form-label" for="displayName">
                  {translation.formatMessage({
                    id: "Display Name",
                    defaultMessage: "Display Name",
                  })}
                </Label>
                <Input
                  id="displayName"
                  placeholder="JohnDoe"
                  value={displayName || ""}
                  onChange={(e) => setDisplayName(e?.target?.value)}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <Label className="form-label" for="email">
                  {translation.formatMessage({
                    id: "Email",
                    defaultMessage: "Email",
                  })}
                </Label>
                <Input
                  id="email"
                  placeholder="john@gmail.com"
                  value={email}
                  onChange={(e) => setEmail(e?.target?.value)}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <Label className="form-label" for="phone">
                  {translation.formatMessage({
                    id: "Phone",
                    defaultMessage: "Phone",
                  })}
                </Label>
                <Input
                  id="phone"
                  placeholder="+92**********"
                  value={phone}
                  onChange={(e) => setPhone(e?.target?.value)}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <Label className="form-label" for="address">
                  {translation.formatMessage({
                    id: "Address",
                    defaultMessage: "Address",
                  })}
                </Label>
                <Input
                  id="address"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => setAddress(e?.target?.value)}
                />
              </Col>
            </Row>
            <Button onClick={handleProfileInfo} color="primary" block>
              {translation.formatMessage({
                id: "Submit",
                defaultMessage: "Submit",
              })}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default AccountSetting;
