import React, { useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';

const DaysSelector = ({ daysOfWeek, initialSelectedDays, toggleDaySelection = () => { }, size, selectedDaysCall = () => { } }) => {
    // State to manage selected days
    const [selectedDays, setSelectedDays] = useState(initialSelectedDays);

    // Function to handle day selection toggling
    const handleDaySelection = (dayId) => {
        setSelectedDays((prevSelectedDays) => {
            const isSelected = prevSelectedDays.includes(dayId);
            if (isSelected) {
                // Remove dayId from selectedDays
                selectedDaysCall(prevSelectedDays.filter(id => id !== dayId));
                return prevSelectedDays.filter(id => id !== dayId);
            } else {
                // Add dayId to selectedDays
                selectedDaysCall([...prevSelectedDays, dayId]);
                return [...prevSelectedDays, dayId];
            }
        });

        // Call the toggleDaySelection function
        toggleDaySelection(dayId);

    };

    // Function to check if a day is selected
    const isDaySelected = (dayId) => selectedDays.includes(dayId);

    return (
        <ButtonGroup className="mb-1">
            {daysOfWeek.map((dayRes) => (
                <Button
                    key={dayRes?.id}
                    outline={!isDaySelected(dayRes?.id)} // Outline if the day is not selected
                    color={"primary"}
                    size={size || "md"}
                    onClick={() => handleDaySelection(dayRes?.id)}
                >
                    {dayRes?.label}
                </Button>
            ))}
        </ButtonGroup>
    );
};

export default DaysSelector;
