import { DashboardIcon, language } from "../../assets/wasfaty/SVG";

const navigationLinks = [
  {
    id: "dashboards",
    slug: "dashboards",
    title: "Dashboards",
    icon: () => DashboardIcon,
    navLink: "/",
  },
];

export default navigationLinks;
