import { createSlice } from "@reduxjs/toolkit";

const allRoutesSlice = createSlice({
  name: "AllRoutesSlice",
  initialState: {
    allRoutesList: [],
  },
  reducers: {
    setAllRoutesSlice: (state, action) => {
      state.allRoutesList = action?.payload;
    },
  },
});

export const { setAllRoutesSlice } = allRoutesSlice.actions;
export default allRoutesSlice.reducer;

// Selectors
export const selectAllRoutesSlice = (state) => state.allRoutesStore.allRoutesList;
